import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export default function UpdateHospitalSubscription({
  modalOpen,
  plansData,
  hospital,
  onSubmit,
  setModalOpen,
}) {
  return (
    <div
      className={`modal fade ${modalOpen ? 'show d-block' : ''}`}
      tabIndex="-1"
      role="dialog"
      style={{ zIndex: 1050 }}
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Manage Subscription</h4>
          </div>
          <div
            className="modal-body"
            style={{ height: '100%', overflowY: 'auto' }}
          >
            {' '}
            <PlanForm
              onSubmit={onSubmit}
              plansData={plansData}
              hospital={hospital}
              setModalOpen={setModalOpen}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const PlanForm = ({ plansData, hospital, onSubmit, setModalOpen }) => {
  console.log({ hospital });
  return (
    <Formik
      initialValues={{
        hospitalPromoCode: hospital?.promoCode?.code || '',
        promoCreditAmount: hospital?.promoCode?.discountAmount,
        promoCreditExpiry: hospital?.promoCode?.expiryDate
          ? new Date(hospital.promoCode.expiryDate).toISOString().split('T')[0]
          : '',
        subscriptionEndDate: hospital?.subscription?.endDate
          ? new Date(hospital?.subscription?.endDate)
              .toISOString()
              .split('T')[0]
          : [],
        plans:
          plansData?.length > 0
            ? plansData?.map((plan) => ({
                _id: plan?._id,
                title: plan.title,
                basePrice: plan.pricePerUser,
                creditAmount: plan?.remainingAmount,
                creditExpiryDate: plan?.creditAmountExpiryDate
                  ? new Date(plan?.creditAmountExpiryDate)
                      .toISOString()
                      .split('T')[0]
                  : '',
              }))
            : [],
      }}
      // validationSchema={Yup.object({
      //   hospitalPromoCode: Yup.string().required('Required'),
      //   promoCreditAmount: Yup.number()
      //     .min(0, 'Must be at least 0')
      //     .required('Required'),
      //   promoCreditExpiry: Yup.date().required('Required'),
      //   subscriptionEndDate: Yup.date().required('Required'),
      //   basePrice: Yup.number()
      //     .min(0, 'Must be at least 0')
      //     .required('Required'),
      //   plans: Yup.array().of(
      //     Yup.object({
      //       creditAmount: Yup.number()
      //         .min(0, 'Must be at least 0')
      //         .required('Required'),
      //       creditExpiryDate: Yup.date().required('Required'),
      //     })
      //   ),
      // })}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values }) => (
        <Form>
          {/* Row 1: Promo Code, Amount, and Expiry Date */}
          <h5>Hospital Promo Code</h5>
          <div className="row">
            <div className="col-md-4">
              <label className="form-label">Promo Code</label>
              <Field
                name="hospitalPromoCode"
                placeholder="Enter promo code"
                className="form-control"
              />
              <ErrorMessage
                name="hospitalPromoCode"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Credit Amount</label>
              <Field
                name="promoCreditAmount"
                type="number"
                placeholder="Enter Amount"
                className="form-control"
              />
              <ErrorMessage
                name="promoCreditAmount"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Credit Expiry Date</label>
              <Field
                name="promoCreditExpiry"
                type="date"
                className="form-control"
              />
              <ErrorMessage
                name="promoCreditExpiry"
                component="div"
                className="text-danger"
              />
            </div>
          </div>

          {/* Row 2: Subscription End Date */}
          <h5 className="mt-3">Subscription End Date</h5>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="subscriptionEndDate"
                type="date"
                className="form-control"
              />
              <ErrorMessage
                name="subscriptionEndDate"
                component="div"
                className="text-danger"
              />
            </div>
          </div>

          {/* Row 3: Base Price */}

          {/* Row 4: Assign Credits to Plans */}
          <h5 className="mt-4 mb-2">Credit Amount & Expiry Date per Plan</h5>
          {values.plans.map((plan, index) => (
            <div key={index} className="row mb-4 mt-2">
              <div className="col-md-3">
                <label className="form-label">Plan</label>
                <p>
                  <label className="form-label">{plan.title}</label>
                </p>
              </div>
              <div className="col-md-3">
                <label className="form-label">Price Per User</label>
                <Field
                  name={`plans[${index}].basePrice`}
                  type="number"
                  className="form-control"
                />
                <ErrorMessage
                  name={`plans[${index}].basePrice`}
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-md-3">
                <label className="form-label">Credit Amount</label>
                <Field
                  name={`plans[${index}].creditAmount`}
                  type="number"
                  placeholder="Credit Amount"
                  className="form-control"
                />
                <ErrorMessage
                  name={`plans[${index}].creditAmount`}
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="col-md-3">
                <label className="form-label">Credit Expiry Date</label>
                <Field
                  name={`plans[${index}].creditExpiryDate`}
                  type="date"
                  className="form-control"
                />
                <ErrorMessage
                  name={`plans[${index}].creditExpiryDate`}
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
          ))}

          <div className="modal-footer mt-4">
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
