import React, { useState, useEffect, useContext, useCallback } from 'react';
import instance from '../../config/config';
import Table from '../Table/Table';
import Navbar from '../Layouts/Navbar';
import MaterialTable from 'material-table';
import { reverse } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AuthContext from '../store/authContext';
import Error from '../UI/Error';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

// Validation Schema
const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  info: yup.string().required('Info is required'),
});
const Resources = () => {
  const [modal, showModal] = useState(false);
  const [resourcesName, setResources] = useState('');
  const [loadedResources, setloadedResources] = useState('');
  const [error, setError] = useState('');

  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const modalHandler = () => {
    showModal(true);
  };

  const closeModalHandler = () => {
    showModal(false);
  };

  const resourceChangeHandler = (event) => {
    setResources(event.target.value);
  };

  const fetchResources = useCallback(() => {
    // setError(null);
    // setloadingSpinner(true);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/acl/resources', { headers: headers })
      .then((response) => {
        const updatedData = response.data.data.map((data) => {
          return data;
        });
        return updatedData;
      })
      .then((data) => {
        setloadedResources(data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || 'Something went wrong!');
      });
  }, []);

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Infio',
      field: 'info',
    },
    {
      title: 'Description',
      field: 'description',
    },

    {
      title: 'Action',
      render: (rowData) => (
        <Link to={`/resources/${rowData._id}`} className="btn btn-success">
          <i class="fa fa-edit"></i>
        </Link>
      ),
    },
  ];

  const formSumbitHandler = (event) => {
    const resourses = {
      newApiResourses: [resourcesName],
    };

    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .post('/acl/resources', { resourses }, { headers: headers })
      .then((response) => {
        fetchResources();
      })
      .catch((err) => {
        setError(err.response.data.message || 'Something went wrong!');
      });
    showModal(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const resourses = {
        newApiResourses: [
          {
            name: data?.name,
            info: data?.info,
            description: data?.description,
          },
        ],
      };

      let headers = {
        'x-access-token': authCtx.token,
        'Content-Type': 'application/json',
      };
      await instance.post(
        '/acl/resources',
        { resourses },
        { headers: headers }
      );
      fetchResources();
      closeModalHandler();
    } catch (error) {
      console.error('Error adding resource:', error);
    }
  };

  return (
    <>
      <Navbar />
      <div>
        {error && <Error err={error} />}
        {modal && (
          // <div className="modal-dialog modal-dialog-centered" tabindex="-1">
          //   <div className="modal-dialog  w-75">
          //     <div className="modal-content">
          //       <form onSubmit={formSumbitHandler} action="">
          //         <div className="modal-header">
          //           <h5 className="modal-title">Add Resources</h5>

          //           <button
          //             onClick={closeModalHandler}
          //             type="button"
          //             class="btn-close"
          //             aria-label="Close"
          //           ></button>
          //         </div>
          //         <div class="modal-body">
          //           <input
          //             onChange={resourceChangeHandler}
          //             className="form-control"
          //             type="text"
          //           />
          //         </div>
          //         <div className="modal-footer">
          //           <button
          //             onClick={closeModalHandler}
          //             type="button"
          //             className="btn btn-secondary"
          //           >
          //             Close
          //           </button>
          //           <button type="submit" className="btn btn-primary">
          //             Save
          //           </button>
          //         </div>
          //       </form>
          //     </div>
          //   </div>
          // </div>
          <div className="modal fade show d-block" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* Modal Header */}
                  <div className="modal-header">
                    <h5 className="modal-title">Add Resource</h5>
                    <button
                      onClick={closeModalHandler}
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                    ></button>
                  </div>

                  {/* Modal Body */}
                  <div className="modal-body">
                    {/* Name Field */}
                    <div className="mb-3">
                      <label className="form-label">Name</label>
                      <input
                        {...register('name')}
                        className={`form-control ${
                          errors.name ? 'is-invalid' : ''
                        }`}
                        type="text"
                      />
                      <div className="invalid-feedback">
                        {errors.name?.message}
                      </div>
                    </div>

                    {/* Info Field */}
                    <div className="mb-3">
                      <label className="form-label">Info</label>
                      <input
                        {...register('info')}
                        className={`form-control ${
                          errors.info ? 'is-invalid' : ''
                        }`}
                        type="text"
                      />
                      <div className="invalid-feedback">
                        {errors.info?.message}
                      </div>
                    </div>
                    {/* Description Field */}
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <textarea
                        {...register('description')}
                        className={`form-control ${
                          errors.description ? 'is-invalid' : ''
                        }`}
                        rows="3"
                      ></textarea>
                      <div className="invalid-feedback">
                        {errors.description?.message}
                      </div>
                    </div>
                  </div>

                  {/* Modal Footer */}
                  <div className="modal-footer">
                    <button
                      onClick={closeModalHandler}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {!modal && loadedResources && (
          <div className="px-4">
            <button
              onClick={modalHandler}
              className="button-3 mt-3"
              // style={{ marginLeft: '50rem' }}
              // to="/add-hospitalDesignation"
            >
              Add Resource
              <i className=" ms-1 fa fa-chevron-right" aria-hidden="true"></i>
            </button>
            <div>{error ? error : ''}</div>
            <div className="col-xl-12 col-log-12 col-md-6">
              <MaterialTable
                title="ACL Resource"
                style={{
                  marginTop: '2rem',
                  // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                  borderRadius: '10px',
                }}
                data={reverse(loadedResources)}
                columns={columns}
              ></MaterialTable>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Resources;
