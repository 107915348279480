import './App.css';
import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from './components/Layouts/Layout';
import Card from './components/Card/Card';
import Hospitaldetails from './components/Pages/HospitalDetails';
import UpdateHospital from './components/Pages/UpdateHospital';
import AddHospital from './components/Pages/AddHospital';
import AuthContext from './components/store/authContext';
import NotFound from './components/Pages/PageNotFound';
import Roles from './components/Pages/Roles';
import Resources from './components/Pages/Resources';
import AclRoles from './components/Pages/AclRoles';
import Managers from './components/Pages/Managers';
import BdmanagerForm from './components/Pages/BdmanagerForm';
import UpdateManager from './components/Pages/UpdateManager';
import HospitalRole from './components/Pages/HospitalRole';
import HospitalDesignation from './components/Pages/HospitalDesignation';
import AddHospitalRole from './components/Pages/AddHospitalRole';
import AddHospitalDesignation from './components/Pages/AddhospitalDesignation';
import HospitalRoleEdit from './components/Pages/HospitalRoleEdit';
import HospitalDesignationEdit from './components/Pages/HospitalDesignationEdit';
import HospitalDepartment from './components/Pages/HospitalDepartment';
import AddHospitalDept from './components/Pages/AddHospitalDept';
import HospitalWard from './components/Pages/HospitalWard';
import AddHospitalWard from './components/Pages/AddHospitalWard';
import HospitalDeptEdit from './components/Pages/HospitalDeptEdit';
import Queries from './components/Pages/Queries';
import AclRoleEdit from './components/Pages/AclRoleEdit';
import AclResourceEdit from './components/Pages/AclResourceEdit';
import AdminProfile from './components/Pages/AdminProfile';
import PasswordOtp from './components/Pages/PasswordOtp';
import PasswordReset from './components/Pages/PasswordReset';
import Check from './components/Layouts/Check';
import User from './components/Pages/Users';
import HospitalAdmin from './components/Pages/HospitalAdmins';
import AdminHospitals from './components/Pages/AdminHospitals';
import Feedback from './components/Pages/Feedbacks';
import Subscription from './components/Pages/Subscriptions';
import TwoStepAuth from './components/login/TwoStepAuth';
import ResetPassword from './components/resetPassword/ResetPassword';
import ManageSubscriptions from './components/Pages/ManageSubscription';
import { Toaster } from 'react-hot-toast';

function App() {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let routes;
  if (token) {
    routes = (
      <div className="App">
        <Layout>
          {/* <Navbar /> */}
          <Toaster />
          <Switch>
            <Route path="/" exact>
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/dashboard" exact>
              <Card />
            </Route>
            <Route path="/hospitals/:hospitalId" exact>
              <UpdateHospital />
            </Route>
            <Route path="/hospitals" exact>
              <Hospitaldetails />
            </Route>
            <Route path="/add-hospital" exact>
              <AddHospital />
            </Route>
            <Route path="/hospital/:hospitalId" exact>
              <User />
            </Route>
            <Route path="/roles" exact>
              <Roles />
            </Route>
            <Route path="/hospital-admins" exact>
              <HospitalAdmin />
            </Route>
            <Route path="/roles/:role" exact>
              <AclRoleEdit />
            </Route>
            <Route path="/primaryHospitals/:userId" exact>
              <AdminHospitals />
            </Route>
            <Route path="/resources" exact>
              <Resources />
            </Route>
            <Route path="/resources/:resource" exact>
              <AclResourceEdit />
            </Route>
            <Route path="/queries" exact>
              <Queries />
            </Route>
            <Route path="/profile" exact>
              <AdminProfile />
            </Route>
            <Route path="/reset" exact>
              <PasswordReset />
            </Route>
            <Route path="/permissions/:roleId" exact>
              <AclRoles />
            </Route>
            <Route path="/Bdmanagers" exact>
              <Managers />
            </Route>
            <Route path="/addmanager" exact>
              <BdmanagerForm />
            </Route>
            <Route path="/feedback" exact>
              <Feedback />
            </Route>
            <Route path="/updatemanager/:managerId" exact>
              <UpdateManager />
            </Route>
            <Route path="/subscriptions" exact>
              <Subscription />
            </Route>
            <Route path="/manage-subscriptions" exact>
              <ManageSubscriptions />
            </Route>
            <Route path="/hospitalRole" exact component={HospitalRole} />
            <Route
              path="/hospitalDesignation"
              exact
              component={HospitalDesignation}
            />
            <Route path="/add-hospitalRole" exact component={AddHospitalRole} />
            <Route
              path="/add-hospitalDesignation"
              exact
              component={AddHospitalDesignation}
            />
            <Route
              path="/hospitalRole/:_id"
              exact
              component={HospitalRoleEdit}
            />
            <Route
              path="/hospitalDesignation/:_id"
              exact
              component={HospitalDesignationEdit}
            />
            <Route
              path="/hospitalDepartment"
              exact
              component={HospitalDepartment}
            />
            <Route
              path="/add-hospitalDepartment"
              exact
              component={AddHospitalDept}
            />

            <Route path="/verify" exact component={PasswordOtp} />
            <Route path="/hospitalWard" exact component={HospitalWard} />
            {/* <Route path="/hospitalWard/:_id" exact component={} /> */}
            <Route path="/add-hospitalWard" exact component={AddHospitalWard} />
            <Route
              path="/hospitalDepartment/:_id"
              exact
              component={HospitalDeptEdit}
            />

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </div>
    );
  } else {
    routes = (
      <div>
        <Switch>
          <Route path="/" exact>
            <TwoStepAuth />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/check" exact component={Check} />

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    );
  }

  return (
    <div>
      <main>{routes}</main>
      {/* <LoginForm />
      <Layout>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/dashboard" />
          </Route>

          <Route path="/dashboard" exact>
            <Card />
          </Route>
          <Route path="/hospitals/:hospitalId" exact>
            <UpdateHospital />
          </Route>
          <Route path="/hospitals" exact>
            <Hospitaldetails />
          </Route>
          <Route path="/add-hospital" exact>
            <AddHospital />
          </Route>
          <Route path="/hospital/:hospitalId" exact>
            <HospitalProfile />
          </Route>
        </Switch>
      </Layout> */}
    </div>
  );
}

export default App;
