import axios from 'axios';
import { useContext } from 'react';
import AuthContext from '../components/store/authContext';

const instance = axios.create({
  // baseURL: 'http://localhost:3020/api',
  baseURL: 'https://api.dailydoc.io/api',
  // baseURL: 'https://staging-api.dailydoc.io/api',
  // headers: {
  //   "x-access-token": authToken,
  //   "Content-Type": "application/json",
  // },
});

export default instance;
