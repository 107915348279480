import { useParams } from 'react-router-dom';
import React, { useContext, useState, useCallback, useEffect } from 'react';
import AuthContext from '../store/authContext';
import instance from '../../config/config';
import Checked from '../checkbox/Checked';
import Error from '../UI/Error';
import Navbar from '../Layouts/Navbar';

const AclRoles = () => {
  const params = useParams();
  const { roleId } = params;

  const [loadedPermissions, setPermissions] = useState([]);
  const [error, setError] = useState(null);

  const authCtx = useContext(AuthContext);

  const fetchPermissions = useCallback(() => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/acl/map', { headers: headers })
      .then((response) => {
        let data = response.data.data.find((e) => e._id === roleId);
        console.log(data.allows);
        setPermissions(data.allows);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  }, []);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  //sync api call
  const syncHandler = () => {
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/acl/syncPermissions', { headers: headers })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  };

  // get method Change

  const getChangeHandler = (id) => {
    let data = loadedPermissions.find((e) => e.resource_id === id);
    const methods = data.methods;
    const get = methods.get;
    methods.get = !get;

    const aclMap = {
      role: roleId,
      allows: [
        {
          resource: id,
          methods: methods,
        },
      ],
    };

    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .post('/acl/map', { aclMap }, { headers: headers })
      .then((response) => {
        fetchPermissions();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  };

  // post method change
  const postChangeHandler = (id) => {
    let data = loadedPermissions.find((e) => e.resource_id === id);
    const methods = data.methods;
    const post = methods.post;
    methods.post = !post;

    const aclMap = {
      role: roleId,
      allows: [
        {
          resource: id,
          methods: methods,
        },
      ],
    };

    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .post('/acl/map', { aclMap }, { headers: headers })
      .then((response) => {
        fetchPermissions();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  };

  // put method change
  const putChangeHandler = (id) => {
    let data = loadedPermissions.find((e) => e.resource_id === id);
    const methods = data.methods;
    const put = methods.put;
    methods.put = !put;

    const aclMap = {
      role: roleId,
      allows: [
        {
          resource: id,
          methods: methods,
        },
      ],
    };

    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .post('/acl/map', { aclMap }, { headers: headers })
      .then((response) => {
        fetchPermissions();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  };

  // const syncHandler = () => {
  //   fetchPermissions();
  // };

  // patch method change
  const patchChangeHandler = (id) => {
    let data = loadedPermissions.find((e) => e.resource_id === id);
    const methods = data.methods;
    const patch = methods.patch;
    methods.patch = !patch;

    const aclMap = {
      role: roleId,
      allows: [
        {
          resource: id,
          methods: methods,
        },
      ],
    };

    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .post('/acl/map', { aclMap }, { headers: headers })
      .then((response) => {
        fetchPermissions();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  };
  // delete method change handler
  const deleteChangeHandler = (id) => {
    let data = loadedPermissions.find((e) => e.resource_id === id);
    const methods = data.methods;
    const del = methods.delete;
    methods.delete = !del;

    const aclMap = {
      role: roleId,
      allows: [
        {
          resource: id,
          methods: methods,
        },
      ],
    };

    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .post('/acl/map', { aclMap }, { headers: headers })
      .then((response) => {
        fetchPermissions();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  };

  const content = loadedPermissions.map((perm) => (
    <tbody>
      <td className=" pb-3" role="button">
        {perm.resource}
      </td>
      <td className=" pb-3" role="button">
        {perm?.description}
      </td>
      <td>
        {perm.methods.get === true && (
          <Checked
            onClick={getChangeHandler.bind(this, perm.resource_id)}
            status={perm.methods.get}
          />
        )}
        {perm.methods.get === false && (
          <Checked
            onClick={getChangeHandler.bind(
              this,
              perm.resource_id,
              perm.methods.get
            )}
            status={perm.methods.get}
          />
        )}
      </td>
      <td>
        {perm.methods.post === true && (
          <Checked
            onClick={postChangeHandler.bind(this, perm.resource_id)}
            status={perm.methods.post}
          />
        )}
        {perm.methods.post === false && (
          <Checked
            onClick={postChangeHandler.bind(this, perm.resource_id)}
            status={perm.methods.post}
          />
        )}
      </td>
      <td>
        {perm.methods.put === true && (
          <Checked
            onClick={putChangeHandler.bind(this, perm.resource_id)}
            status={perm.methods.put}
          />
        )}
        {perm.methods.put === false && (
          <Checked
            onClick={putChangeHandler.bind(this, perm.resource_id)}
            status={perm.methods.put}
          />
        )}
      </td>
      <td>
        {perm.methods.patch === true && (
          <Checked
            onClick={patchChangeHandler.bind(this, perm.resource_id)}
            status={perm.methods.patch}
          />
        )}
        {perm.methods.patch === false && (
          <Checked
            onClick={patchChangeHandler.bind(this, perm.resource_id)}
            status={perm.methods.patch}
          />
        )}
      </td>
      <td>
        {perm.methods.delete === true && (
          <Checked
            onClick={deleteChangeHandler.bind(this, perm.resource_id)}
            status={perm.methods.delete}
          />
        )}
        {perm.methods.delete === false && (
          <Checked
            onClick={deleteChangeHandler.bind(this, perm.resource_id)}
            status={perm.methods.delete}
          />
        )}
      </td>
    </tbody>
  ));

  return (
    <>
      <Navbar />
      <div className="row p-4">
        {error && <Error err={error} />}
        <div className="card">
          <h4 className=" card-header text-center text-dark bg-white rounded-top position-relative">
            <i className="fa fa-edit me-2"></i>
            <u>Permissions</u>
            <button
              onClick={syncHandler}
              className="btn btn-primary position-absolute bottom-0 end-0"
            >
              Sync
            </button>
          </h4>

          <div className="col-md-6 col-xl-12 table-responsive">
            <table className="table p-2 table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Get</th>
                  <th scope="col">Post</th>
                  <th scope="col">Put</th>
                  <th scope="col">Patch</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              {content}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default AclRoles;
