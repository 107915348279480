import React, { useContext, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import Navbar from '../Layouts/Navbar';
import SubscriptionDetailsModal from '../subscription/SubscriptionDetailModal';
import toast from 'react-hot-toast';
import instance from '../../config/config';
import AuthContext from '../store/authContext';
import swal from 'sweetalert';
import UpdateHospitalSubscription from '../subscription/UpdateHospitalSUbscription';

const ManageSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [openSubscriptionDetail, setopenSubscriptionDetail] = useState(false);
  const [subs, setSubs] = useState(null);
  const [planForm, setPlanForm] = useState(false);
  const [rowData, setRowData] = useState(null);

  const authCtx = useContext(AuthContext);

  const getHospitalsData = async () => {
    try {
      let response = await instance.get('/hospitals/primaryHospitals/bd', {
        headers: {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        },
      });
      setSubscriptions(response?.data?.data);
      console.log({ response });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getHospitalsData();
  }, []);

  const handleSuspend = (subsription) => {
    swal({
      title: 'Suspend Hospital',
      text: `Are you sure you want suspend this hospital?`,
      buttons: true,
      dangerMode: true,
    }).then((willSuspend) => {
      if (willSuspend) {
        let url = `/bd/hospitals/${subs?._id}/suspend`;

        //  setError(null);
        let headers = {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        };

        instance
          .post(url, {}, { headers: headers })
          .then((response) => {
            toast.success('Hospital suspended succesfully');
            getHospitalsData();
            setopenSubscriptionDetail(false);
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      }
    });
  };

  const activateHospital = (hospitalId) => {
    swal({
      title: 'Activate Hospital',
      text: `Are you sure you want activate this hospital?`,
      buttons: true,
      dangerMode: true,
    }).then((willSuspend) => {
      if (willSuspend) {
        let url = `/bd/hospitals/${subs?._id}/activate`;

        //  setError(null);
        let headers = {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        };

        instance
          .post(url, {}, { headers: headers })
          .then((response) => {
            toast.success('Hospital activated succesfully');
            getHospitalsData();
            setopenSubscriptionDetail(false);
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      }
    });
  };

  const handleSubmit = async (values) => {
    try {
      let formData = {
        ...values,
      };

      let response = await instance.post(
        `/subscriptions/hospitals/${rowData?._id}`,
        formData,
        {
          headers: {
            'x-access-token': authCtx.token,
            'Content-Type': 'application/json',
          },
        }
      );
      getHospitalsData();
      setPlanForm(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      {' '}
      <Navbar />
      <div className="container-fluid py-4">
        <h3 className="mt-4">Subscription Plans for Hospitals</h3>
        <MaterialTable
          style={{
            marginTop: '2rem',
            borderRadius: '10px',
          }}
          title="Hospital Subscription Plans"
          columns={[
            { title: 'Hospital', field: 'name', filtering: false },
            { title: 'Short Name', field: 'shortName' },
            { title: 'City', field: 'city' },
            { title: 'State', field: 'state' },
            { title: 'Country', field: 'country' },

            // Subscription Plan Details
            { title: 'Plan', field: 'subscription.plan.title' },
            {
              title: 'Currency',
              field: 'subscription.currency',
              type: 'string',
            },
            {
              title: 'Price',
              field: 'subscription.totalPrice',
              type: 'numeric',
            },
            {
              title: 'Price Per User',
              field: 'subscription.basePrice',
              type: 'numeric',
            },
            // {
            //   title: 'User Limit',
            //   field: 'subscription.subscription.userLimit',
            //   type: 'numeric',
            // },
            {
              title: 'Users',
              field: 'subscription.usersCount',
              type: 'numeric',
            },
            {
              title: 'Extra Users',
              field: 'subscription.extraUserCount',
              type: 'numeric',
            },
            {
              title: 'Extra User Price',
              field: 'subscription.extraUserPrice',
              type: 'numeric',
            },
            {
              title: 'Duration (Months)',
              field: 'subscription.duration',
              type: 'numeric',
            },

            // Discount & Promo Codes
            {
              title: 'Discount Amount',
              field: 'subscription.remainingCreditAmount',
              type: 'numeric',
            },

            {
              title: 'Promo Codes',
              field: 'subscription.promoCode',
              render: (rowData) =>
                rowData?.promoCode?.code ? (
                  <span>
                    {rowData.promoCode.code} - {rowData.subscription.currency}{' '}
                    {rowData.promoCode.discountAmount}
                  </span>
                ) : (
                  'No Promo Code'
                ),
            },
            {
              title: 'Status',
              field: 'suspendedBybd',
              type: 'boolean',
              render: (rowData) => (
                <div>
                  {!rowData.suspendedBybd && (
                    <span className=" badge badge-success">active</span>
                  )}
                  {rowData.suspendedBybd && (
                    <span className="badge badge-danger">Inactive</span>
                  )}
                </div>
              ),
            },
            {
              title: 'Subscription Expiry',
              field: 'subscription.endDate',
              render: (rowData) => {
                const endDate = rowData?.subscription?.endDate
                  ? new Date(rowData.subscription.endDate)
                  : null;

                if (!endDate) return 'N/A'; // Handle missing endDate

                const currentDate = new Date();
                const isExpired = endDate < currentDate;

                return (
                  <div>
                    <span>{endDate.toDateString()}</span>
                    <br />
                    {isExpired ? (
                      <span className="badge bg-danger">
                        Expired on {endDate.toDateString()}
                      </span>
                    ) : (
                      <span className="badge bg-success">
                        expire on {endDate.toDateString()}
                      </span>
                    )}
                  </div>
                );
              },
            },
            {
              title: 'Actions',
              render: (rowData) => (
                <div>
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      setRowData(rowData);
                      setPlanForm(true);
                    }}
                    className="btn btn-secondary"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                </div>
              ),
            },
          ]}
          onRowClick={(event, row) => {
            setSubs(row);
            setopenSubscriptionDetail(true);
          }}
          data={subscriptions || []}
          options={{
            search: true,
            pageSize: 10,
            pageSizeOptions: [5, 10, 20],
            exportButton: true,
            actionsColumnIndex: -1,
          }}
        />
      </div>
      <UpdateHospitalSubscription
        setModalOpen={setPlanForm}
        modalOpen={planForm}
        plansData={rowData?.plans}
        onSubmit={handleSubmit}
        hospital={rowData}
      />
      {openSubscriptionDetail && (
        <SubscriptionDetailsModal
          modalOpen={openSubscriptionDetail}
          setClose={setopenSubscriptionDetail}
          subscription={subs}
          onSuspend={handleSuspend}
          onActivate={activateHospital}
        />
      )}
    </>
  );
};

export default ManageSubscriptions;
