import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import instance from '../../config/config';
import AuthContext from '../store/authContext';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  info: yup.string().required('Info is required'),
  description: yup.string().required('Description is required'),
});

const AclResourceEdit = () => {
  const { resource: id } = useParams();
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const closeModalHandler = () => {
    history.push('/resources');
  };

  const fetchSingleResource = useCallback(() => {
    setLoading(true);
    setError(null);
    const headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };

    instance
      .get(`/acl/resources/${id}`, { headers })
      .then((response) => {
        const data = response.data.data;
        setValue('name', data.name);
        setValue('info', data.info);
        setValue('description', data.description);
      })
      .catch((err) => {
        if (err.response?.status === 401) authCtx.logout();
        setError(err.response?.data?.message || 'Something went wrong!');
      })
      .finally(() => setLoading(false));
  }, [id, authCtx, setValue]);

  useEffect(() => {
    fetchSingleResource();
  }, [fetchSingleResource]);

  const onSubmit = (data) => {
    setLoading(true);
    const headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    const formData = {
      resource: {
        name: data?.name,
        description: data?.description,
        info: data?.info,
      },
    };
    instance
      .patch(`/acl/resources/${id}`, formData, { headers })
      .then(() => history.push('/resources'))
      .catch((err) => {
        if (err.response?.status === 401) authCtx.logout();
        setError(err.response?.data?.message || 'Something went wrong!');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="modal fade show d-block" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title">Edit Resource</h5>
              <button
                onClick={closeModalHandler}
                type="button"
                className="btn-close"
                aria-label="Close"
              ></button>
            </div>

            {/* Modal Body */}
            <div className="modal-body">
              {error && <div className="alert alert-danger">{error}</div>}

              {/* Name Field */}
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  {...register('name')}
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  type="text"
                />
                <div className="invalid-feedback">{errors.name?.message}</div>
              </div>

              {/* Info Field */}
              <div className="mb-3">
                <label className="form-label">Info</label>
                <input
                  {...register('info')}
                  className={`form-control ${errors.info ? 'is-invalid' : ''}`}
                  type="text"
                />
                <div className="invalid-feedback">{errors.info?.message}</div>
              </div>

              {/* Description Field */}
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  {...register('description')}
                  className={`form-control ${
                    errors.description ? 'is-invalid' : ''
                  }`}
                  rows="3"
                ></textarea>
                <div className="invalid-feedback">
                  {errors.description?.message}
                </div>
              </div>
            </div>

            {/* Modal Footer */}
            <div className="modal-footer">
              <button
                onClick={closeModalHandler}
                type="button"
                className="btn btn-secondary"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Update'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AclResourceEdit;
