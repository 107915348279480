import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './sideBar.css';
import logo from '../../assets/dailydoclogo.svg';

const MainNavigation = () => {
  const location = useLocation();

  return (
    <React.Fragment>
      <div className="wid">
        <section className="section-content">
          <div className="row">
            <aside className="col-lg-3">
              <nav className="sidebar bg-dark py-2 mb-4">
                <ul className="nav flex-column" id="nav_accordion">
                  {/* Logo */}
                  <li className="nav-item">
                    <header className="sidebarlogo bg-dark px-4 text-white d-flex align-items-center">
                      <img
                        src={logo}
                        alt="Daily Doc Logo"
                        className="logo-img"
                      />
                      <span className="ms-3 blockquote">Daily Doc</span>
                    </header>
                  </li>

                  {/* Dashboard */}
                  <li className="nav-item mt-4">
                    <NavLink
                      className={`nav-link text-white ${
                        location.pathname === '/dashboard' ? 'active-tab' : ''
                      }`}
                      to="/dashboard"
                    >
                      <i className="fa fa-tachometer ms-2 me-2"></i> Dashboard
                    </NavLink>
                  </li>

                  {/* Admins */}
                  <li className="nav-item">
                    <NavLink
                      className={`nav-link text-white ${
                        location.pathname === '/hospital-admins'
                          ? 'active-tab'
                          : ''
                      }`}
                      to="/hospital-admins"
                    >
                      <i className="fa fa-users ms-2 me-2"></i> Admins
                    </NavLink>
                  </li>

                  {/* Queries */}
                  <li className="nav-item">
                    <NavLink
                      className={`nav-link text-white ${
                        location.pathname === '/queries' ? 'active-tab' : ''
                      }`}
                      to="/queries"
                    >
                      <i className="fa fa-question-circle ms-2 me-2"></i>{' '}
                      Queries
                    </NavLink>
                  </li>

                  {/* Feedback */}
                  <li className="nav-item">
                    <NavLink
                      className={`nav-link text-white ${
                        location.pathname === '/feedback' ? 'active-tab' : ''
                      }`}
                      to="/feedback"
                    >
                      <i className="fa fa-comments ms-2 me-2"></i> Feedbacks
                    </NavLink>
                  </li>

                  {/* Plans */}
                  <li className="nav-item">
                    <NavLink
                      className={`nav-link text-white ${
                        location.pathname === '/subscriptions'
                          ? 'active-tab'
                          : ''
                      }`}
                      to="/subscriptions"
                    >
                      <i className="fa fa-list-alt ms-2 me-2"></i> Plans
                    </NavLink>
                  </li>

                  {/* Subscriptions */}
                  <li className="nav-item">
                    <NavLink
                      className={`nav-link text-white ${
                        location.pathname === '/manage-subscriptions'
                          ? 'active-tab'
                          : ''
                      }`}
                      to="/manage-subscriptions"
                    >
                      <i className="fa fa-credit-card-alt ms-2 me-2"></i>{' '}
                      Subscriptions
                    </NavLink>
                  </li>

                  {/* ACL Dropdown */}
                  <li className="nav-item">
                    <div
                      className="accordion accordion-flush"
                      id="aclAccordion"
                    >
                      <div className="accordion-item">
                        <h5 className="accordion-header">
                          <button
                            className="accordion-button collapsed bg-dark text-white"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#aclMenu"
                          >
                            <i className="fa fa-users ms-2 me-2"></i> ACL
                          </button>
                        </h5>
                        <div
                          id="aclMenu"
                          className="accordion-collapse collapse"
                          data-bs-parent="#aclAccordion"
                        >
                          <div className="accordion-body bg-dark">
                            <NavLink
                              className={`nav-link text-white ${
                                location.pathname === '/roles'
                                  ? 'active-tab'
                                  : ''
                              }`}
                              to="/roles"
                            >
                              <i className="fa fa-user-tag"></i> ACL/Roles
                            </NavLink>
                            <NavLink
                              className={`nav-link text-white ${
                                location.pathname === '/resources'
                                  ? 'active-tab'
                                  : ''
                              }`}
                              to="/resources"
                            >
                              <i className="fa fa-folder-open"></i> Resources
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  {/* Hospital Dropdown */}
                  <li className="nav-item">
                    <div
                      className="accordion accordion-flush"
                      id="hospitalAccordion"
                    >
                      <div className="accordion-item">
                        <h5 className="accordion-header">
                          <button
                            className="accordion-button collapsed bg-dark text-white"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#hospitalMenu"
                          >
                            <i className="fa fa-h-square ms-2 me-2"></i>{' '}
                            Hospital
                          </button>
                        </h5>
                        <div
                          id="hospitalMenu"
                          className="accordion-collapse collapse"
                          data-bs-parent="#hospitalAccordion"
                        >
                          <div className="accordion-body bg-dark">
                            <NavLink
                              className={`nav-link text-white ${
                                location.pathname === '/hospitals'
                                  ? 'active-tab'
                                  : ''
                              }`}
                              to="/hospitals"
                            >
                              <i className="fa fa-clinic-medical"></i> Hospitals
                            </NavLink>
                            <NavLink
                              className={`nav-link text-white ${
                                location.pathname === '/hospitalRole'
                                  ? 'active-tab'
                                  : ''
                              }`}
                              to="/hospitalRole"
                            >
                              <i className="fa fa-user-md"></i> Roles
                            </NavLink>
                            <NavLink
                              className={`nav-link text-white ${
                                location.pathname === '/hospitalDesignation'
                                  ? 'active-tab'
                                  : ''
                              }`}
                              to="/hospitalDesignation"
                            >
                              <i className="fa fa-id-badge"></i> Designations
                            </NavLink>
                            <NavLink
                              className={`nav-link text-white ${
                                location.pathname === '/hospitalDepartment'
                                  ? 'active-tab'
                                  : ''
                              }`}
                              to="/hospitalDepartment"
                            >
                              <i className="fa fa-building"></i> Departments
                            </NavLink>
                            <NavLink
                              className={`nav-link text-white ${
                                location.pathname === '/hospitalWard'
                                  ? 'active-tab'
                                  : ''
                              }`}
                              to="/hospitalWard"
                            >
                              <i className="fa fa-procedures"></i> Wards
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </aside>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default MainNavigation;
