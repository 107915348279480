import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import instance from '../../config/config';
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import AuthContext from '../store/authContext';
import { countries } from '../../utility/common';
import logo from '../../assets/dailydoclogo.svg';

const schemaStep1 = yup.object().shape({
  mobile: yup
    .string()
    .matches(/^\d{10,15}$/, 'Enter a valid mobile number')
    .required('Mobile number is required'),
  countryCode: yup.string().required('Country code is required'),
});

const schemaStep2 = yup.object().shape({
  otp: yup
    .string()
    .length(6, 'OTP must be 6 digits')
    .required('OTP is required'),
});

const schemaStep3 = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
});
const schemaStep4 = yup.object().shape({
  emailOtp: yup
    .string()
    .length(6, 'OTP must be 6 digits')
    .required('OTP is required'),
});

const schemaStep5 = yup.object().shape({
  firstCar: yup.string().required('Answer is required'),
  childhoodFriend: yup.string().required('Answer is required'),
});

const schemaStep6 = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one symbol, and be at least eight characters long'
    )
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const ResetPassword = () => {
  const [step, setStep] = useState(1);
  const [otpSent, setOtpSent] = useState(false);
  const [otpId, setOtpId] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      [
        schemaStep1,
        schemaStep2,
        schemaStep3,
        schemaStep4,
        schemaStep5,
        schemaStep6,
      ][step - 1]
    ),
  });

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const sendMobileOTP = async (data) => {
    setMobile(`${data.countryCode} ${data.mobile}`);
    try {
      let formData = {
        mobileData: {
          mobile: data?.mobile,
          countryCode: data?.countryCode,
        },
      };
      const response = await instance.post(
        '/auth/bdManager/otp/mobile',
        formData
      );

      if (response) {
        setOtpId(response.data.data.otpId);
        setOtpSent(true);
        setStep(2);
      }
    } catch (error) {
      swal('Error', error?.response?.data?.message, 'error');
    }
  };

  const verifyMobileOTP = async (data) => {
    try {
      const response = await instance.post(
        '/auth/bdManager/otp/mobile/verify',
        {
          _id: otpId,
          otp: data.otp,
        }
      );

      if (response) {
        setToken(response?.data?.data?.token);
        setStep(3);
      }
    } catch (error) {
      swal('Error', error?.response?.data?.message, 'error');
    }
  };

  const sendEmailOTP = async (data) => {
    setEmail(data.email);
    let formData = {
      emailData: {
        email: data?.email,
        token: token,
      },
    };

    try {
      const response = await instance.post(
        '/auth/bdManager/otp/email',
        formData
      );

      if (response) {
        setOtpId(response.data.data.otpId);
        setStep(4);
      }
    } catch (error) {
      swal('Error', error?.response?.data?.message, 'error');
    }
  };

  const verifyEmailOTP = async (data) => {
    try {
      let formData = {
        otpData: {
          otpId,
          otp: data?.emailOtp,
        },
      };
      const response = await instance.post(
        '/auth/bdManager/otp/email/verify',
        formData
      );
      if (response) {
        setToken(response?.data?.data?.token);
        setStep(5);
      }
    } catch (error) {
      swal('Error', error?.response?.data?.message, 'error');
    }
  };

  const submitSecurityQuestions = async (data) => {
    try {
      let formData = {
        token: token,
        question1: 'What was my first car?',
        question2: "who's my childhood friend?",
        answer1: data?.firstCar,
        answer2: data?.childhoodFriend,
      };
      const response = await instance.post(
        '/auth/bdManager/securityQuestion',
        formData
      );
      if (response) {
        setToken(response.data.data.token);
        setStep(6);
      }
    } catch (error) {
      swal('Error', 'Something went wrong. Try again.', 'error');
    }
  };

  const setNewPassword = async (data) => {
    try {
      let formData = {
        password: data?.password,
        confirmPassword: data?.confirmPassword,
        token: token,
      };
      const response = await instance.post(
        '/auth/bdManager/password/reset',
        formData
      );

      if (response) {
        swal({
          title: 'Success',
          text: 'Your password has been reset successfully!',
          icon: 'success',
          button: 'OK',
        }).then(() => {
          history.push('/login'); // Redirect to login when OK is clicked
        });
      }
    } catch (error) {
      swal('Error', error?.response?.data?.message, 'error');
    }
  };

  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ minHeight: '100vh' }}
    >
      <div className="card shadow-sm p-4" style={{ width: '500px' }}>
        <div className="text-center mb-4">
          <img src={logo} alt="" />
          <p className="text-primary fw-bold me-1">Reset Password</p>
        </div>

        {step === 1 && (
          <form onSubmit={handleSubmit(sendMobileOTP)}>
            <div className="mb-3">
              <label className="form-label">Country Code</label>
              <select className="form-select" {...register('countryCode')}>
                {countries.map((country) => (
                  <option key={country.label} value={country.phone}>
                    {country.label}
                  </option>
                ))}
              </select>
              {errors.countryCode && (
                <div className="text-danger">{errors.countryCode.message}</div>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Mobile Number</label>
              <input
                type="text"
                className="form-control"
                {...register('mobile')}
              />
              {errors.mobile && (
                <div className="text-danger">{errors.mobile.message}</div>
              )}
            </div>
            <div className="text-end mb-2">
              <a href="/login" className="text-decoration-none text-primary">
                Back to Login?
              </a>
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Send OTP
            </button>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleSubmit(verifyMobileOTP)}>
            <div className="mb-3">
              <p className="text-center">OTP sent to {mobile}</p>
              <input
                type="text"
                className="form-control"
                {...register('otp')}
                placeholder="Enter OTP"
              />
              {errors.otp && (
                <div className="text-danger">{errors.otp.message}</div>
              )}
            </div>
            <div className="text-end mb-2">
              <a href="/login" className="text-decoration-none text-primary">
                Back to Login?
              </a>
            </div>
            <button type="submit" className="btn btn-success w-100 mb-3">
              Verify OTP
            </button>

            <button
              type="button"
              className="btn btn-secondary w-100"
              onClick={handleBack}
            >
              Back
            </button>
          </form>
        )}

        {step === 3 && (
          <form onSubmit={handleSubmit(sendEmailOTP)}>
            <div className="mb-3">
              <p className="text-center">Mobile: {mobile}</p>
              <input
                type="email"
                className="form-control"
                {...register('email')}
                placeholder="Enter Email"
              />
              {errors.email && (
                <div className="text-danger">{errors.email.message}</div>
              )}
            </div>
            <div className="text-end mb-2">
              <a href="/login" className="text-decoration-none text-primary">
                Back to Login?
              </a>
            </div>
            <button type="submit" className="btn btn-primary w-100 mb-2">
              Send OTP
            </button>
            <button
              type="button"
              className="btn btn-secondary w-100 mt-2"
              onClick={handleBack}
            >
              Back
            </button>
          </form>
        )}

        {step === 4 && (
          <form onSubmit={handleSubmit(verifyEmailOTP)}>
            <div className="mb-3">
              <p className="text-center">OTP sent to {email}</p>
              <input
                type="text"
                className="form-control"
                {...register('emailOtp')}
                placeholder="Enter OTP"
              />
              {errors.otp && (
                <div className="text-danger">{errors.otp.message}</div>
              )}
            </div>
            <div className="text-end mb-2">
              <a href="/login" className="text-decoration-none text-primary">
                Back to Login?
              </a>
            </div>
            <button type="submit" className="btn btn-success w-100 mb-3">
              Verify OTP
            </button>

            <button
              type="button"
              className="btn btn-secondary w-100"
              onClick={handleBack}
            >
              Back
            </button>
          </form>
        )}

        {step === 5 && (
          <form onSubmit={handleSubmit(submitSecurityQuestions)}>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="What was my first car?"
              {...register('firstCar')}
            />
            <input
              type="text"
              className="form-control mb-3"
              placeholder="who's my childhood friend?"
              {...register('childhoodFriend')}
            />
            <div className="text-end mb-2">
              <a href="/login" className="text-decoration-none text-primary">
                Back to Login?
              </a>
            </div>
            <button type="submit" className="btn btn-primary w-100 mb-2">
              Next
            </button>
            <button
              type="button"
              className="btn btn-secondary w-100 mt-2"
              onClick={handleBack}
            >
              Back
            </button>
          </form>
        )}

        {step === 6 && (
          <form onSubmit={handleSubmit(setNewPassword)}>
            <input
              type="password"
              className="form-control mb-3"
              placeholder="New Password"
              {...register('password')}
            />
            {errors.password && (
              <div className="text-danger">{errors.password.message}</div>
            )}
            <input
              type="password"
              className="form-control mb-3"
              placeholder="Confirm Password"
              {...register('confirmPassword')}
            />
            {errors.confirmPassword && (
              <div className="text-danger">
                {errors.confirmPassword.message}
              </div>
            )}
            <button type="submit" className="btn btn-success w-100">
              Set Password
            </button>
            <button
              type="button"
              className="btn btn-secondary w-100 mt-2"
              onClick={handleBack}
            >
              Back
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
