import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import instance from '../../config/config';
import AuthContext from '../store/authContext';
import Navbar from '../Layouts/Navbar';
import MaterialTable from 'material-table';
import { currencylist } from '../../utility/common';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';
import swal from 'sweetalert';

const Subscription = () => {
  const authCtx = useContext(AuthContext);
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState(null);
  const [feature, setFeature] = useState(null);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [featureModalOpen, setFeatureModalOpen] = useState(false);

  const fetchSubscriptions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await instance.get('/subscriptions/plans', {
        headers: {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        },
      });
      setPlans(response.data.data.plans);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }, [authCtx.token]);

  const fetchFeatures = useCallback(async () => {
    setLoading(true);
    try {
      const response = await instance.get('/subscriptions', {
        headers: {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        },
      });
      setFeatures(response.data.data.features);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }, [authCtx.token]);

  useEffect(() => {
    fetchSubscriptions();
    fetchFeatures();
  }, [fetchSubscriptions, fetchFeatures]);

  const planSchema = Yup.object().shape({
    title: Yup.string().required('Plan name is required'),
    subtitle: Yup.string().required('Subtitle is required'),
    pricePerUser: Yup.number()
      .required('Price per user is required')
      .positive('Must be positive'),
    duration: Yup.string().required('Duration is required'),
    type: Yup.string()
      .required('Plan type is required')
      .oneOf(['STARTER', 'GROWTH', 'ENTERPRISE']),
    currency: Yup.string().required('Currency is required'),
  });

  const featureSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    plans: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one plan must be selected'),
  });

  const MyDatePicker = ({ field, form, ...props }) => {
    return (
      <DatePicker
        {...field}
        {...props}
        selected={field.value}
        onChange={(date) => form.setFieldValue(field.name, date)}
        minDate={new Date()} // Ensures only future dates can be selected
        dateFormat="yyyy-MM-dd"
        placeholder="Discount duration"
        placeholderText="Select Date"
        className="form-control form-control-lg"
      />
    );
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      // Filter out empty promo codes
      const validPromoCodes = values.promoCodes
        .filter((promo) => promo.code.trim() !== '')
        .map((promo) => ({
          code: promo.code.trim(),
          discountAmount: parseFloat(promo.discountAmount) || 0,
          expiryDate: promo.expiryDate, // Ensure expiryDate is properly formatted
        }));

      // Structure data as per Mongoose schema
      const subscriptionDetails = {
        title: values.title.trim(),
        subtitle: values.subtitle.trim(),
        pricePerUser: values.pricePerUser,
        userLimit: values.userLimit,
        duration: values.duration,
        discount: values?.discount,
        discountDuration: values?.discountDuration,
        currency: values.currency.trim(),
        type: values.type,
        promoCodes: validPromoCodes,
        active: true, // Default active status
        planId: plan?._id,
        creditExpiryDurationInMonth: values?.creditDuration,
        creditAmount: values?.creditAmount,
      };

      // API Call
      await instance.post('/subscriptions/plans', subscriptionDetails, {
        headers: {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      // Success: Reset form and close modal
      setPlan(null);
      resetForm();
      setModalOpen(false);
      fetchSubscriptions();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFeatureSubmit = async (values, { resetForm }) => {
    try {
      let formValue = {
        feature: values?.description,
        plans: values?.plans,
        featureId: feature?._id,
      };

      await instance.post('/subscriptions', formValue, {
        headers: {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      setPlan(null);
      setFeature(null);
      fetchFeatures(); // Refresh feature list
      resetForm({}); // Reset form fields
      setFeatureModalOpen(false); // Close modal
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const deleteFeature = async (featureId) => {
    swal({
      title: 'Delete',
      text: `Are you sure you want delete this feature`,
      buttons: true,
      dangerMode: true,
    }).then((willSuspend) => {
      if (willSuspend) {
        instance
          .delete(`/subscriptions/${featureId}`, {
            headers: {
              'x-access-token': authCtx.token,
              'Content-Type': 'application/json',
            },
          })
          .then(() => fetchFeatures())
          .catch((error) => toast.error(error?.response?.data?.message));
      }
    });
  };

  return (
    <>
      <Navbar />
      <div className="container py-4">
        <div className="mb-3 p-3 border rounded bg-light">
          <h5 className="fw-bold">Manage Subscription Plans</h5>
          <p className="mb-2">
            Easily create and manage subscription plans for hospitals. Define
            pricing, user limits, and subscription duration. Add promo codes and
            discounts to customize plans according to needs.
          </p>
          <p className="mb-2">
            Click the <strong>"Add Subscription Plan"</strong> button below to
            create a new plan.
          </p>
          {/* Button to open modal */}
          <button
            className="btn btn-primary"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Add Subscription Plan
          </button>
        </div>

        <div className="mb-3 p-3 border rounded bg-light">
          {' '}
          <h5 className="fw-bold">Manage Features</h5>{' '}
          <p className="mb-2">
            {' '}
            Define and manage key features available in different subscription
            plans. Easily assign features to specific plans to offer customized
            benefits for hospitals.{' '}
          </p>{' '}
          <p className="mb-2">
            {' '}
            Click the <strong>"Add Feature to Plan"</strong> button below to
            create and assign new features to plans.{' '}
          </p>{' '}
          {/* Button to open modal */}
          <button
            className="btn btn-primary"
            onClick={() => setFeatureModalOpen(true)}
          >
            Add Feature to Plan
          </button>
        </div>

        {/* Subscription Plans Table */}
        <div className="mb-4">
          <MaterialTable
            title="Plans"
            data={plans}
            columns={[
              { title: 'Name', field: 'title' },
              { title: 'Subtitle', field: 'subtitle' },
              { title: 'Price Per User', field: 'pricePerUser' },
              { title: 'User Limit', field: 'userLimit' },
              { title: 'Duration', field: 'duration' },
              { title: 'Discount (%)', field: 'discount' },
              {
                title: 'Promo Codes',
                field: 'promoCodes',
                render: (rowData) =>
                  rowData.promoCodes
                    .map((p) => `${p.code} (${p.discountAmount})`)
                    .join(', '),
              },

              {
                title: 'Actions',
                render: (rowData) => (
                  <div>
                    <button
                      onClick={() => {
                        setPlan(rowData);
                        setModalOpen(true);
                      }}
                      className="btn btn-secondary"
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                  </div>
                ),
              },
            ]}
          />
        </div>

        <MaterialTable
          title="Features"
          data={features}
          columns={[
            { title: 'Feature', field: 'feature' },
            {
              title: 'Plans',
              field: 'plans',
              render: (rowData) =>
                rowData.plans.map((plan) => plan.type).join(', '),
            },
            {
              title: 'Actions',
              render: (rowData) => (
                <div className="d-flex">
                  <button
                    onClick={() => {
                      setFeature(rowData);
                      setFeatureModalOpen(true);
                    }}
                    className="btn btn-secondary me-2"
                  >
                    <i className="fa fa-edit"></i>
                  </button>

                  <button
                    onClick={() => {
                      deleteFeature(rowData?._id);
                    }}
                    className="btn btn-secondary"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              ),
            },
          ]}
        />

        {/* Bootstrap Modal */}
      </div>
      <div
        className={`modal fade ${modalOpen ? 'show d-block' : ''}`}
        tabIndex="-1"
        role="dialog"
        style={{ zIndex: 1050 }}
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable ">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Plan</h4>
            </div>

            {/* Scrollable Modal Body */}
            <div
              className="modal-body"
              style={{
                height: '100%', // Limit modal body height
                overflowY: 'auto', // Enable scrolling
              }}
            >
              <Formik
                initialValues={{
                  title: plan?.title || '',
                  subtitle: plan?.subtitle || '',
                  pricePerUser: plan?.pricePerUser || '',
                  userLimit: plan?.userLimit || '',
                  duration: plan?.duration || '',
                  discount: plan?.discount || '',
                  promoCodes: plan?.promoCodes
                    ? plan?.promoCodes.map((promo) => ({
                        code: promo.code || '',
                        discountAmount: promo.discountAmount || '',
                        expiryDate: promo.expiryDate
                          ? new Date(promo.expiryDate)
                          : null, // Convert expiryDate to Date object
                      }))
                    : [{ code: '', discountAmount: '', expiryDate: null }],
                  type: plan?.type || '',
                  currency: plan?.currency || '',
                  charge: plan?.charge || '',
                  discountDuration: plan?.discountDuration || '',
                  creditAmount: plan?.creditAmount || '',
                  creditDuration: plan?.creditExpiryDuration || '',
                }}
                validationSchema={planSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({ values, setFieldValue, resetForm, handleReset }) => (
                  <Form>
                    <div className="row">
                      {/* Title */}
                      <div className="col-md-6">
                        <label className="form-label">Title</label>
                        <Field
                          name="title"
                          placeholder="Title"
                          className="form-control form-control-lg"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* Subtitle */}
                      <div className="col-md-6">
                        <label className="form-label">Subtitle</label>
                        <Field
                          name="subtitle"
                          placeholder="Subtitle"
                          className="form-control form-control-lg"
                        />
                        <ErrorMessage
                          name="subtitle"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* Currency */}
                      <div className="col-md-6 mt-3">
                        <label className="form-label">Currency</label>
                        <Field
                          as="select"
                          name="currency"
                          className="form-select"
                        >
                          <option value="">Select currency</option>
                          {currencylist?.map((currency) => (
                            <option value={currency?.value}>
                              {currency?.label}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="currency"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* Price Per User */}
                      <div className="col-md-3 mt-3">
                        <label className="form-label">Price Per User</label>
                        <Field
                          name="pricePerUser"
                          type="number"
                          placeholder="Enter price per user"
                          className="form-control form-control-lg"
                        />
                        <ErrorMessage
                          name="pricePerUser"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* Charge */}
                      <div className="col-md-3 mt-3">
                        <label className="form-label">Charge</label>
                        <Field
                          as="select"
                          name="charge"
                          className="form-select"
                        >
                          <option value="">Charge</option>
                          <option value="MONTHLY">Monthly</option>
                          <option value="ANNUALLY">Annually</option>
                        </Field>
                        <ErrorMessage
                          name="charge"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* Plan Type */}
                      <div className="col-md-6 mt-3">
                        <label className="form-label">Plan Type</label>
                        <Field as="select" name="type" className="form-select">
                          <option value="">Select Plan Type</option>
                          <option value="STARTER">Starter</option>
                          <option value="GROWTH">Growth</option>
                          <option value="ENTERPRISE">Enterprise</option>
                        </Field>
                        <ErrorMessage
                          name="type"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* User Limit */}
                      <div className="col-md-6 mt-3">
                        <label className="form-label">User Limit</label>
                        <Field
                          name="userLimit"
                          type="number"
                          placeholder="Enter user limit"
                          className="form-control form-control-lg"
                        />
                        <ErrorMessage
                          name="userLimit"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* Duration */}
                      <div className="col-md-6 mt-3">
                        <label className="form-label">
                          Billing Duration (Months)
                        </label>
                        <Field
                          as="select"
                          name="duration"
                          className="form-select"
                        >
                          <option value="">Billing Duration</option>
                          <option value="1">1 Month</option>
                          <option value="3">3 Months</option>
                          <option value="6">6 Months</option>
                          <option value="12">12 Months</option>
                        </Field>
                        <ErrorMessage
                          name="duration"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* Discount */}
                      <div className="col-md-3 mt-3">
                        <label className="form-label">Discount (%)</label>
                        <Field
                          name="discount"
                          type="number"
                          placeholder="Discount (%)"
                          className="form-control form-control-lg"
                        />
                        <ErrorMessage
                          name="discount"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* Discount Duration */}
                      <div className="col-md-3 mt-3">
                        <label className="form-label">Discount (Months)</label>
                        <Field
                          as="select"
                          name="discountDuration"
                          className="form-select"
                        >
                          <option value="">Discount Duration</option>
                          <option value="1">1 Month</option>
                          <option value="3">3 Months</option>
                          <option value="6">6 Months</option>
                          <option value="12">12 Months</option>
                        </Field>
                        <ErrorMessage
                          name="discountDuration"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label className="form-label">Credit Amount</label>
                        <Field
                          name={`creditAmount`}
                          type="number"
                          placeholder="Credit Amount"
                          className="form-control"
                        />
                        <ErrorMessage
                          name={`creditAmount`}
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <label className="form-label">Credit Duration</label>
                        <Field
                          as="select"
                          name="creditDuration"
                          className="form-select"
                        >
                          <option value="1">1 Month</option>
                          <option value="3">3 Months</option>
                          <option value="6">6 Months</option>
                          <option value="12">12 Months</option>
                        </Field>
                        <ErrorMessage
                          name="creditDuration"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* Promo Codes */}
                      <div className="col-md-12 mt-3">
                        <h5>Promo Codes</h5>
                        {values.promoCodes.map((_, index) => (
                          <div className="row mb-2" key={index}>
                            <div className="col-md-6">
                              <label className="form-label">Code</label>
                              <Field
                                name={`promoCodes[${index}].code`}
                                placeholder="Promo Code"
                                className="form-control form-control-lg"
                              />
                              <ErrorMessage
                                name={`promoCodes[${index}].code`}
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="col-md-2">
                              <label className="form-label">Amount</label>
                              <Field
                                name={`promoCodes[${index}].discountAmount`}
                                type="number"
                                placeholder="Discount Amount"
                                className="form-control form-control-lg"
                              />
                              <ErrorMessage
                                name={`promoCodes[${index}].discountAmount`}
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="col-md-2">
                              <label className="form-label">Expiry Date</label>
                              <Field
                                name={`promoCodes[${index}].expiryDate`}
                                component={MyDatePicker}
                              />
                              <ErrorMessage
                                name={`promoCodes[${index}].expiryDate`}
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="col-md-2 d-flex flex-column">
                              <button
                                type="button"
                                className="btn btn-danger mt-auto"
                                onClick={() =>
                                  setFieldValue(
                                    'promoCodes',
                                    values.promoCodes.filter(
                                      (_, i) => i !== index
                                    )
                                  )
                                }
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="btn btn-secondary mt-1"
                          onClick={() => {
                            setFieldValue('promoCodes', [
                              ...values.promoCodes,
                              {
                                code: '',
                                discountAmount: '',
                                expiryDate: '',
                              },
                            ]);
                          }}
                        >
                          Add Promo Code
                        </button>
                      </div>
                    </div>

                    {/* Submit & Close Buttons */}
                    <div className="modal-footer mt-4">
                      <button type="submit" className="btn btn-primary">
                        {plan ? 'Update Plan' : 'Add Plan'}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          handleReset();
                          setPlan({});
                          resetForm({});
                          setModalOpen(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* Feature Modal */}
      <div className={`modal fade ${featureModalOpen ? 'show d-block' : ''}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Feature</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setFeature({});
                  setFeatureModalOpen(false);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{
                  description: feature ? feature?.feature : '',
                  plans: feature?.plans
                    ? feature?.plans?.map((p) => p._id)
                    : [],
                }}
                validationSchema={featureSchema}
                onSubmit={handleFeatureSubmit}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <Field
                      as="textarea"
                      name="description"
                      placeholder="Description"
                      className="form-control mt-3"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                    />

                    <div className="mt-3">
                      <label>Select Plans</label>
                      {plans?.map((plan) => (
                        <div key={plan._id}>
                          <input
                            type="checkbox"
                            value={plan._id}
                            checked={
                              values?.plans?.includes(plan?._id) || false
                            } // Pre-select on edit
                            onChange={(e) => {
                              setFieldValue(
                                'plans',
                                e.target.checked
                                  ? [...new Set([...values.plans, plan._id])]
                                  : values.plans.filter((p) => p !== plan._id)
                              );
                            }}
                          />{' '}
                          {plan.type}
                        </div>
                      ))}
                    </div>
                    <ErrorMessage
                      name="plans"
                      component="div"
                      className="text-danger"
                    />

                    <div className="modal-footer mt-4">
                      <button type="submit" className="btn btn-primary">
                        {feature ? 'Update feature' : 'Add Feature'}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setFeatureModalOpen(false)}
                      >
                        Close
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
