import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import logo from '../../assets/dailydoclogo.svg';
import instance from '../../config/config';
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import AuthContext from '../store/authContext';
import { countries } from '../../utility/common';
import toast from 'react-hot-toast';

const schemaStep1 = yup.object().shape({
  identifier: yup
    .string()
    .required('Email or mobile number is required')
    .test('is-valid', 'Enter a valid email or mobile number', (value) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^\d{10,15}$/;
      return emailRegex.test(value) || phoneRegex.test(value);
    }),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

const schemaStep2 = yup.object().shape({
  otp: yup
    .string()
    .length(6, 'OTP must be 6 digits')
    .required('OTP is required'),
});

const TwoStepAuth = () => {
  const [step, setStep] = useState(1);
  const [otpSent, setOtpSent] = useState(false);
  const [otpId, setOtpId] = useState('');
  const [identifier, setIdentifier] = useState('');
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(step === 1 ? schemaStep1 : schemaStep2),
  });

  const identifierValue = watch('identifier', '');
  const isPhoneNumber = /^\d+$/.test(identifierValue);

  const sendOTP = async (data) => {
    // setStep(2);
    setIdentifier(data.identifier);
    let formData = {
      bdLogin: {
        password: data?.password,
      },
    };

    if (isPhoneNumber) {
      formData.bdLogin.mobile = data?.identifier;
      formData.bdLogin.countryCode = data?.countryCode;
    } else {
      formData.bdLogin.email = data?.identifier;
    }

    try {
      const response = await instance.post('/auth/bd/login', formData);

      if (response) {
        setOtpId(response.data.data.otpId);
        setOtpSent(true);
        setStep(2);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const verifyOTP = async (data) => {
    try {
      let formData = {
        otpData: {
          otpId: otpId,
          otp: data?.otp,
        },
      };

      const response = await instance.post('/auth/bd/login/otp', formData);

      if (response) {
        authCtx.user(response.data.data.userName, response.data.data.userId);
        authCtx.login(response.data.data.token);
        history.push('/dashboard');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ minHeight: '100vh' }}
    >
      <div className="card shadow-sm p-4" style={{ width: '500px' }}>
        <div className="text-center mb-4">
          <img src={logo} alt="" />
          <p className="text-primary fw-bold me-1">BD Login</p>
        </div>

        {step === 1 && (
          <form onSubmit={handleSubmit(sendOTP)}>
            <div className="mb-3">
              <label className="form-label">Email or Mobile Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter email or mobile"
                {...register('identifier')}
                onChange={(e) => setValue('identifier', e.target.value)}
              />
              {errors.identifier && (
                <div className="text-danger">{errors.identifier.message}</div>
              )}
            </div>

            {isPhoneNumber && (
              <div className="mb-3">
                <label className="form-label">Country Code</label>
                <select className="form-select" {...register('countryCode')}>
                  {countries?.map((country) => {
                    return (
                      <option key={country?.label} value={country?.phone}>
                        {country?.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            <div className="mb-3">
              <label className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                {...register('password')}
              />
              {errors.password && (
                <div className="text-danger">{errors.password.message}</div>
              )}
            </div>
            <div className="text-end mb-2">
              <a
                href="/reset-password"
                className="text-decoration-none text-primary"
              >
                Reset Password?
              </a>
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Send OTP
            </button>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleSubmit(verifyOTP)}>
            <div className="mb-3">
              <label className="form-label">Enter OTP</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter OTP"
                {...register('otp')}
              />
              {errors.otp && (
                <div className="text-danger">{errors.otp.message}</div>
              )}
            </div>
            <a
              href="/reset-password"
              className="text-decoration-none text-primary"
            >
              Reset Password?
            </a>
            <button type="submit" className="btn btn-success w-100">
              Verify OTP
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default TwoStepAuth;
