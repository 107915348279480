import React from 'react';

const SubscriptionDetailsModal = ({
  subscription,
  onSuspend,
  onActivate,
  modalOpen,
  setClose,
}) => {
  // const registrationDate = new Date(subscription.createdAt);
  // const expirationDate = new Date(subscription?.subscription?.endDate);
  // expirationDate.setMonth(expirationDate.getMonth() + subscription.duration);

  // const currentDate = new Date();
  // const timeDiff = expirationDate - currentDate;
  // const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert ms to days

  // let statusBadge;
  // if (daysLeft > 0) {
  //   statusBadge = (
  //     <span className="badge bg-success">
  //       {daysLeft} Day{daysLeft > 1 ? 's' : ''}
  //     </span>
  //   );
  // } else {
  //   const daysExtended = Math.abs(daysLeft);
  //   statusBadge = (
  //     <span className="badge bg-danger">
  //       Expired{' '}
  //       {daysExtended > 0
  //         ? `(${daysExtended} Day${daysExtended > 1 ? 's' : ''} Overdue)`
  //         : ''}
  //     </span>
  //   );
  // }

  return (
    <div
      className={`modal fade ${modalOpen ? 'show d-block' : ''}`}
      tabIndex="-1"
      role="dialog"
      id="subscriptionModal"
      aria-labelledby="subscriptionModalLabel"
      aria-hidden="true"
      style={{ zIndex: 1050 }}
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="subscriptionModalLabel">
              Subscription Details
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setClose(false)}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Hospital</th>
                  <td>{subscription?.name}</td>
                </tr>
                <tr>
                  <th>Country</th>
                  <td>{subscription?.country}</td>
                </tr>
                <tr>
                  <th>State</th>
                  <td>{subscription?.state}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{subscription?.city}</td>
                </tr>

                <tr>
                  <th>Plan</th>
                  <td>{subscription?.subscription?.plan?.title}</td>
                </tr>
                <tr>
                  <th> Expiry Date</th>

                  <td>
                    {' '}
                    <strong>
                      {new Date(
                        subscription?.subscription?.endDate
                      ).toDateString()}
                    </strong>
                  </td>
                </tr>
                {/* <tr>
                  <th>Expires in</th>

                  <td>{statusBadge}</td>
                </tr> */}
                <tr>
                  <th>Status</th>
                  <td>
                    <span
                      className={`badge ${
                        !subscription?.suspendedBybd
                          ? 'bg-success'
                          : 'bg-danger'
                      }`}
                    >
                      {!subscription?.suspendedBybd ? 'Active' : 'Suspended'}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>
                    {subscription?.currency}{' '}
                    {subscription?.subscription?.totalPrice}
                  </td>
                </tr>
                <tr>
                  <th>Price Per User</th>
                  <td>
                    {subscription?.currency}{' '}
                    {subscription?.subscription?.basePrice}
                  </td>
                </tr>
                <tr>
                  <th>User Limit</th>
                  <td>{subscription?.subscription?.usersCount}</td>
                </tr>

                <tr>
                  <th>Discount</th>
                  <td>{subscription?.subscription?.remainingCreditAmount}</td>
                </tr>
                <tr>
                  <th>Promo Codes</th>
                  <td>
                    {subscription?.subscription?.promoCodes?.length > 0
                      ? subscription.promoCodes.map((promo, index) => (
                          <p key={index}>
                            {promo.code} -{' '}
                            {subscription?.subscription?.currency}{' '}
                            {promo.discountAmount}
                          </p>
                        ))
                      : 'No Promo Codes'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className={
                subscription?.suspendedBybd
                  ? 'btn btn-success'
                  : 'btn btn-danger'
              }
              onClick={() => {
                subscription?.suspendedBybd
                  ? onActivate(subscription)
                  : onSuspend(subscription);
              }}
            >
              {subscription?.suspendedBybd ? 'Activate' : 'Suspend'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetailsModal;
